<template>
  <ul class="relative w-full">
    <li
      v-for="accordionItem in accordionItems"
      :key="accordionItem.title"
      class="mb-5 last:mb-0"
    >
      <item v-bind="accordionItem" />
    </li>
  </ul>
</template>

<script>
import Item from './item'

export default {
  components: {
    Item,
  },
  props: {
    accordionItems: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
