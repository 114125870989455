
import { PropOptions } from 'vue'
import { Document } from '@contentful/rich-text-types'
import { Card, Divider, Collapsable, UiTitle } from '~/components/atoms'
import RichTextRenderer from '~/components/renderers/rich-text-renderer/index.vue'

export default {
  components: {
    Card,
    Divider,
    Collapsable,
    UiTitle,
    RichTextRenderer,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: [String, Object],
      default: () => ({}),
    } as PropOptions<Document | Record<string, unknown> | string>,
    isOpenByDefault: {
      type: Boolean,
      default: false,
    },
  },
}
